import React, {useState} from 'react';
import './App.scss';
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import HttpApi from 'i18next-http-backend'
import MultiLoadBackendAdapter from 'i18next-multiload-backend-adapter'
import config from './i18n.common'
import {useMediaQuery} from "react-responsive";

declare global {
    interface Window {
        grecaptcha: any;
        sg: {
            launch: any
        };
    }
}
i18n.use(initReactI18next)
    .use(MultiLoadBackendAdapter)
    .init({
        //defaultNS: 'web',
        keySeparator: false,
        ns: config.ns,
        lowerCaseLng: true,
        backend: {
            backend: HttpApi,
            backendOption: {
                loadPath: 'https://api.oshcasino.com/api/translations/{{ns}}',
                customHeaders: () => ({
                    'accept-language': 'ru',
                    // ...
                }),
                parse: (dataString: any, language: any) => {
                    const data = JSON.parse(dataString)
                    const lang = language[0]
                    return {[lang]: data};
                }
            }
        },
        lng: "en",
        updateMissing: true,
        nsSeparator: ':',
        fallbackLng: false,
        ignoreJSONStructure: true
    });

function App() {
    const [menuOpen, setMenuOpen] = useState(false)

    const { t } = useTranslation();
    const isMobile = useMediaQuery({ maxWidth: 900 });

    function scrollToSection(id: any, offset = 200) {
        if (isMobile) {
            setMenuOpen(false);
        }
        const section = document.getElementById(id);
        // @ts-ignore
        const top = section.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({ top: top - offset, behavior: 'smooth' });
    }
  return (
    <div className="app">
      <header className="app-header">
          <div className="app-header__container">
              {isMobile && !menuOpen && (
                  <img draggable="false"
                       src="/svg/burger.svg"
                       alt="decoration"
                       loading="lazy"
                       className="app-header__menu--burger"
                       onClick={()=> setMenuOpen(!menuOpen)}
                  />
              )}
              {isMobile && menuOpen && (
                  <img draggable="false"
                       src="/svg/close.svg"
                       alt="decoration"
                       loading="lazy"
                       className="app-header__menu--close"
                       onClick={()=> setMenuOpen(!menuOpen)}
                  />
              )}
              <img draggable="false"
                   className="app-header__logo"
                   src="/logo.png"
                   alt="OSH logo"
                  loading="lazy"
              />
              {!isMobile && (
                  <div className="app-header__menu">
                      <div className="app-header__menu--item" onClick={() => scrollToSection('main', 125)}>{t('web.affiliate:main')}</div>
                      <div className="app-header__menu--item" onClick={() => scrollToSection('statistics', 200)}>{t('web.affiliate:stats')}</div>
                      <div className="app-header__menu--item" onClick={() => scrollToSection('affiliate-main', 50)}>{t('web.affiliate:affiliates')}</div>
                      <div className="app-header__menu--item" onClick={() => scrollToSection('contactUs', 200)}>{t('web.affiliate:contact us')}</div>
                  </div>
              )}
              <div className="app-header__actions">
                  <a href='https://affiliates.oshcasino.com/login' target="_blank" rel="noopener noreferrer" className="main-button">
                      {t('web.affiliate:Sign Up')}
                  </a>
              </div>
          </div>
          {isMobile && menuOpen && (
              <div className="app-header__menu-mobile">
                  <div className="app-header__menu-mobile--item" onClick={() => scrollToSection('main', 400)}>{t('web.affiliate:main')}</div>
                  <div className="app-header__menu-mobile--item" onClick={() => scrollToSection('statistics', 425)}>{t('web.affiliate:stats')}</div>
                  <div className="app-header__menu-mobile--item" onClick={() => scrollToSection('affiliate-main', 400)}>{t('web.affiliate:affiliates')}</div>
                  <div className="app-header__menu-mobile--item" onClick={() => scrollToSection('contactUs', 475)}>{t('web.affiliate:contact us')}</div>
              </div>
          )}
      </header>
      <div className="app-main">
          <img draggable="false"
               className="app-main__decoration2"
               src="/logos/logoDecoration1.png"
               alt="OSH logo"
               loading="lazy"
          />
          <img draggable="false"
               className="app-main__decoration1"
               src="/logos/logoDecoration2.png"
               alt="OSH logo"
               loading="lazy"
          />
          <div className="screen">
              <img draggable="false"
                  className="screen__logo"
                   src="/logos/mainLogo.png"
                  alt="OSH logo"
                  loading="lazy"
              />
              <img draggable="false"
                   className="screen__decoration"
                   src="/logos/logoDecoration.png"
                   alt="OSH logo"
                   loading="lazy"
              />
          </div>
          <div className="welcome" id='main'>
              <div className="welcome__logo">
                  <img draggable="false"
                      src="/logos/secondLogo.png"
                      alt="OSH logo"
                      loading="lazy"
                  />
              </div>
              <div className="welcome__text">
                  {t('web.affiliate:Welcome to OSH Casino – your ultimate digital gaming destination! Immerse yourself in a diverse selection of slots, live casino experiences, and shows. Enjoy a vast array of games from top developers, creating an endless choice of slots for every taste.')}
              </div>
          </div>
          <div className="statistics" id='statistics'>
              <img draggable="false"
                   className="statistics__decoration"
                   src="/logos/statisticsDecoration.png"
                   alt="decor"
                   loading="lazy"
              />
              <div className="content-header">
                  <h6 className="content-header--text">
                      {t('web.affiliate:OSH CASINO')}
                  </h6>
                  <h4 className="content-header--name">
                      {t('web.affiliate:CHOSE BY PLAYERS')}
                      <img draggable="false"
                          className="statistics__header--character"
                          src="/img/character3.png"
                          alt="character"
                          loading="lazy"
                      />
                  </h4>
              </div>
              <div className="statistics__container">
                  <img draggable="false"
                      className="statistics__container--character2"
                      src="/img/character2.png"
                      alt="character"
                      loading="lazy"
                  />
                  <div className="statistics__item">
                      <div className="statistics__item--value">{t('web.affiliate:10 MIN')}</div>
                      <div className="statistics__item--name">{t('web.affiliate:Average WITHDRAW TIME')}</div>
                  </div>
                  <div className="statistics__item">
                      <div className="statistics__item--value">{t('web.affiliate:24/7')}</div>
                      <div className="statistics__item--name">{t('web.affiliate:ONLINE SUPPORT')}</div>
                  </div>
                  <div className="statistics__item">
                      <div className="statistics__item--value">{t('web.affiliate:2,400+')}</div>
                      <div className="statistics__item--name">{t('web.affiliate:Casino Games')}</div>
                      <img draggable="false"
                          className="statistics__item--character1"
                          src="/img/character1.png"
                          alt="character"
                          loading="lazy"
                      />
                  </div>
                  <div className="statistics__item">
                      <div className="statistics__item--value">{t('web.affiliate:10+')}</div>
                      <div className="statistics__item--name">{t('web.affiliate:DEPOSIT METHODS')}</div>
                  </div>
                  <div className="statistics__item">
                      <div className="statistics__item--value">{t('web.affiliate:50,000+')}</div>
                      <div className="statistics__item--name">{t('web.affiliate:PLAYERS')}</div>
                  </div>
                  <div className="statistics__item">
                      <div className="statistics__item--value">{t('web.affiliate:37')}</div>
                      <div className="statistics__item--name">{t('web.affiliate:SUPPORTED COUNTRIES')}</div>
                  </div>
              </div>
          </div>
          <div className="action">
              <div className="content-header">
                  <h6 className="content-header--text">
                      {t('web.affiliate:CAN’T DECIDE YET?')}
                  </h6>
                  <h4 className="content-header--name">
                      {t('web.affiliate:EXPLORE OSH CASINO')}
                  </h4>
              </div>
              <a href='https://oshcasino.com/' target="_blank" rel="noopener noreferrer" className="main-button">{t('web.affiliate:EXPLORE NOW')}</a>
          </div>
      </div>
        <div className="affiliate-main" id="affiliate-main">
            <h4 className="affiliate-text">
                {t('web.affiliate:Want to become an')} &nbsp;<span className="affiliate-text--acent">{t('web.affiliate:affiliate')}</span> ?
            </h4>
            <div className="offer">
                <div className="offer__decoration4"/>
                <img draggable="false"
                    className="offer__decoration5"
                    src="/decorations/decoration4.png"
                    alt="decoration"
                    loading="lazy"
                />
                <div className="offer-header">
                    <h4 className="offer-header__name">{t('web.affiliate:WE OFFER')}</h4>
                    <h6 className="offer-header__text">{t('web.affiliate:Clear commission system')}</h6>
                    <div className="offer-header__decoration"/>
                </div>
                <div className="offer-list">
                    <div className="offer-list__item">
                        <img draggable="false"
                            className="offer-list__decoration3"
                            src="/decorations/decoration3.png"
                            alt="decoration"
                            loading="lazy"
                        />
                        <div className="offer-list__container">
                            <div className="offer-list__name">{t('web.affiliate:Revenue Share')}</div>
                            <div className="offer-list__text">{t('web.affiliate:Receive a profit share based on your referral performance')}</div>
                        </div>
                    </div>
                    <div className="offer-list__item">
                        <img draggable="false"
                            className="offer-list__decoration2"
                            src="/decorations/decoration2.png"
                            alt="decoration"
                            loading="lazy"
                        />
                        <div className="offer-list__container">
                            <div className="offer-list__name">{t('web.affiliate:Cost Per Acquisition')}</div>
                            <div className="offer-list__text">{t('web.affiliate:Get paid per every referral you send our way')}</div>
                        </div>
                    </div>
                    <div className="offer-list__item">
                        <img draggable="false"
                            className="offer-list__decoration1"
                            src="/decorations/decoration1.png"
                            alt="decoration"
                            loading="lazy"
                        />
                        <div className="offer-list__container">
                            <div className="offer-list__name">{t('web.affiliate:Hybrid Plan')}</div>
                            <div className="offer-list__text">{t('web.affiliate:Combination of both commission plans')}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="promotion">
                <div className="promotion__header">
                    <h6 className="promotion__header--text">{t('web.affiliate:ALL WHAT YOU NEED')}</h6>
                    <h4 className="promotion__header--name">{t('web.affiliate:JOIN. ADVERTISE. EARN.')}</h4>
                    <div className="promotion__header--span">{t('web.affiliate:Get what you earn, always on time')}</div>
                </div>
                <div className="promotion__main">
                    <div className="promotion__main-item">
                        <div className="promotion__main-item--value">{t('web.affiliate:5 MIN')}</div>
                        <div className="promotion__main-item--name">{t('web.affiliate:Average Cash Out Time')}</div>
                    </div>
                    <div className="promotion__main-item">
                        <div className="promotion__main-item--value">{t('web.affiliate:3')}</div>
                        <div className="promotion__main-item--name">{t('web.affiliate:CoMMISIONS SYSTEM')}</div>
                    </div>
                    <div className="promotion__main-item">
                        <div className="promotion__main-item--value">{t('web.affiliate:12')}</div>
                        <div className="promotion__main-item--name">{t('web.affiliate:CASH OUT METHODS')}</div>
                    </div>
                </div>
            </div>
            <div className="product">
                <div className="product__header">
                    <h6 className="product__header--text">{t('web.affiliate:OUR PRODUCT')}</h6>
                    <img draggable="false"
                        className="product__header--img"
                        src="/decorations/product.png"
                        alt="decoration"
                        loading="lazy"
                    />
                </div>
                <div className="product__main">
                    <div className="product__main-item">
                        <div className="product__main-item--name">
                            {t('web.affiliate:OUR PRODUCT')}
                        </div>
                        <div className="product__main-item--text">
                            {t('web.affiliate:Slots, Live Casino, Table Games.')}
                        </div>
                    </div>
                    <div className="product__main-item">
                        <div className="product__main-item--name">
                            {t('web.affiliate:SOFTWARE')}
                        </div>
                        <div className="product__main-item--text">
                            {t('web.affiliate:1x2 Gaming, 4theplayer, Authentic Gaming, Amatic Industries, Avatar UX, Booon')}
                        </div>
                    </div>
                    <div className="product__main-item">
                        <div className="product__main-item--name">
                            {t('web.affiliate:Deposit METHODS')}
                        </div>
                        <div className="product__main-item--text">
                            {t('web.affiliate:Visa, MasterCard, Coinspaid (Bitcoin, Litecoin, Bitcoin Cash, Etherum, Dogecoin, Tether)')}
                        </div>
                    </div>
                    <div className="product__main-item">
                        <div className="product__main-item--name">
                            {t('web.affiliate:Withdraw METHODS')}
                        </div>
                        <div className="product__main-item--text">
                            {t('web.affiliate:Visa, MasterCard (please view Terms & Conditions on countries that are supported), Coinspaid')}
                        </div>
                    </div>
                    <div className="product__main-item">
                        <div className="product__main-item--name">
                            {t('web.affiliate:LANGUAGES')}
                        </div>
                        <div className="product__main-item--text">
                            {t('web.affiliate:English, Russian, Portuguese.')}
                        </div>
                    </div>
                    <div className="product__main-item">
                        <div className="product__main-item--name">
                            {t('web.affiliate:RESTRICTED COUNTRIES')}
                        </div>
                        <div className="product__main-item--text">
                            {t('web.affiliate:United States of America, Greece, United Kingdom, Jersey, Gibraltar, Spain, France and its overseas')}
                        </div>
                    </div>
                    <div className="product__main-item">
                        <div className="product__main-item--name">
                            {t('web.affiliate:CURRENCIES')}
                        </div>
                        <div className="product__main-item--text">
                            {t('web.affiliate:Australian dollars, Canadian Dollar, Euros, Norwegian kroner, Russian rubles, US dollars')}
                        </div>
                    </div>
                    <div className="product__main-item">
                        <div className="product__main-item--name">
                            {t('web.affiliate:RESTRICTED COMPAINGS')}
                        </div>
                        <div className="product__main-item--text">
                            {t('web.affiliate:Porn related content, Email marketing, cold calling, OSH naming in compaings.')}
                        </div>
                    </div>
                </div>
            </div>
            <div className="comments">
                <div className="comments__header">
                    <h6 className="comments__header--text">{t('web.affiliate:WHAT OTHERS SAY')}</h6>
                    <h4 className="comments__header--name">{t('web.affiliate:TESTIMONIALS')}</h4>
                </div>
                <div className="comments__main">
                    <div className="comments__main-item">
                        <img draggable="false"
                            className="comments__main-item--decoration"
                            src="/decorations/decorations8.png"
                            alt="decoration"
                            loading="lazy"
                        />
                        <div className="comments__main-item--text">{t('web.affiliate:OSH Casino\'s affiliate program is a joy to work with! Their seamless platform and detailed analytics simplify tracking, while the captivating design and diverse games attract players effortlessly. The transparent, lucrative commission structure makes this partnership not only enjoyable but highly profitable. I highly recommend OSH Casino\'s affiliate program to those seeking a reliable collaboration.')}</div>
                        <div className="comments__main-item--name">
                            <span>{t('web.affiliate:CRAZYDOGZ team')}</span> &nbsp;
                            {t('web.affiliate:SWEDEN')}
                        </div>
                    </div>
                    <div className="comments__main-item">
                        <img draggable="false"
                            className="comments__main-item--decoration"
                            src="/decorations/decorations8.png"
                            alt="decoration"
                            loading="lazy"
                        />
                        <div className="comments__main-item--text">{t('web.affiliate:OSH Casino\'s affiliate program is a joy to work with! Their seamless platform and detailed analytics simplify tracking, while the captivating design and diverse games attract players effortlessly. The transparent, lucrative commission structure makes this partnership not only enjoyable but highly profitable. I highly recommend OSH Casino\'s affiliate program to those seeking a reliable collaboration.')}</div>
                        <div className="comments__main-item--name">
                            <span>{t('web.affiliate:DIAMONDS777')}</span> &nbsp;
                            {t('web.affiliate:POLAND')}
                        </div>
                    </div>
                    <div className="comments__main-item">
                        <img draggable="false"
                            className="comments__main-item--decoration"
                            src="/decorations/decorations8.png"
                            alt="decoration"
                            loading="lazy"
                        />
                        <div className="comments__main-item--text">{t('web.affiliate:OSH Casino\'s affiliate program is a joy to work with! Their seamless platform and detailed analytics simplify tracking, while the captivating design and diverse games attract players effortlessly. The transparent, lucrative commission structure makes this partnership not only enjoyable but highly profitable. I highly recommend OSH Casino\'s affiliate program to those seeking a reliable collaboration.')}</div>
                        <div className="comments__main-item--name">
                            <span>{t('web.affiliate:METAWOLFS')}</span> &nbsp;
                            {t('web.affiliate:HIDdeN')}
                        </div>
                    </div>
                    <div className="comments__main-item">
                        <img draggable="false"
                            className="comments__main-item--decoration"
                            src="/decorations/decorations8.png"
                            alt="decoration"
                            loading="lazy"
                        />
                        <div className="comments__main-item--text">OSH Casino's affiliate program is a game-changer! The user-friendly platform and in-depth analytics simplify performance tracking. The casino's enticing design and diverse game offerings make it a magnet for players. The transparent commission structure ensures not just an enjoyable collaboration but a highly lucrative one. OSH Casino is a top choice for those looking to elevate their earnings in the online casino affiliate space. This partnership has been a pleasant surprise, and I'm excited about the continued success it promises.</div>
                        <div className="comments__main-item--name">
                            <span>{t('web.affiliate:John BLESS LTD')}</span> &nbsp;
                            {t('web.affiliate:CYPRUS')}
                        </div>
                    </div>
                </div>
            </div>
            <div className="team">
                <div className="team__header">
                    <h6 className="team__header--text">{t('web.affiliate:WE HERE TO HELP')}</h6>
                    <h4 className="team__header--name">{t('web.affiliate:MEET THE TEAM')}</h4>
                </div>
                <div className="team__main">
                    <div className="team__main-item">
                        <div className="team__main-item--photo">
                            <img draggable="false"
                                src="/contacts/info.png"
                                alt="decoration"
                                loading="lazy"
                            />
                        </div>
                        <div className="team__main-item--name">{t('web.affiliate:Osh Casino')}</div>
                        <div className="team__main-item--position">{t('web.affiliate:Contact Email')}</div>
                        <div className="team__main-item--mail">
                            <img draggable="false"
                                src="/svg/mail.svg"
                                alt="decoration"
                                loading="lazy"
                            />
                            <span>
                                {t('web.affiliate:osh.casino@oshparners.com')}
                            </span>
                        </div>
                    </div>
                    <div className="team__main-item">
                        <div className="team__main-item--photo">
                            <img draggable="false"
                                src="/contacts/photo.png"
                                alt="decoration"
                                loading="lazy"
                            />
                        </div>
                        <div className="team__main-item--name">{t('web.affiliate:Osh Casino')}</div>
                        <div className="team__main-item--position">{t('web.affiliate:Contact Email')}</div>
                        <div className="team__main-item--mail">
                            <img draggable="false"
                                src="/svg/mail.svg"
                                alt="decoration"
                                loading="lazy"
                            />
                            <span>
                                {t('web.affiliate:osh.casino@oshparners.com')}
                            </span>
                        </div>
                    </div>
                    <div className="team__main-item">
                        <div className="team__main-item--photo">
                            <img draggable="false"
                                src="/contacts/photo.png"
                                alt="decoration"
                                loading="lazy"
                            />
                        </div>
                        <div className="team__main-item--name">{t('web.affiliate:Osh Casino')}</div>
                        <div className="team__main-item--position">{t('web.affiliate:Contact Email')}</div>
                        <div className="team__main-item--mail">
                            <img draggable="false"
                                src="/svg/mail.svg"
                                alt="decoration"
                                loading="lazy"
                            />
                            <span>
                                {t('web.affiliate:osh.casino@oshparners.com')}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contactUs" id="contactUs">
                <div className="team__header">
                    <h6 className="team__header--text">{t('web.affiliate:Let’s start our journey')}</h6>
                    <h4 className="team__header--name">{t('web.affiliate:CONTACT US')}</h4>
                </div>
                <div>
                    <a href='https://affiliates.oshcasino.com/registration' target="_blank" rel="noopener noreferrer" className="main-button">{t('web.affiliate:Become a Partner')}</a>
                </div>
            </div>
            <div className="blog">
                <div className="team__header">
                    <h6 className="team__header--text">{t('web.affiliate:OUR BLOG')}</h6>
                </div>
                <div className="blog-main">
                    <div className="blog-main__item">
                        <div className="blog-main__item--pic">
                            <img draggable="false"
                                src="/img/blog.png"
                                alt="decoration"
                                loading="lazy"
                            />
                        </div>
                        <div className="blog-main__item--name">
                            {t('web.affiliate:Revenue Share')}
                        </div>
                        <div className="blog-main__item--text">
                            {t('web.affiliate:Receive a profit share based on your referral performance')}
                        </div>
                        <div className="blog-main__item--link">
                            {t('web.affiliate:READ ARTICLE')}
                            <img draggable="false"
                                src="/svg/arrow.svg"
                                alt="decoration"
                                loading="lazy"
                            />
                        </div>
                    </div>
                    <div className="blog-main__item">
                        <div className="blog-main__item--pic">
                            <img draggable="false"
                                src="/img/blog.png"
                                alt="decoration"
                                loading="lazy"
                            />
                        </div>
                        <div className="blog-main__item--name">
                            {t('web.affiliate:Revenue Share')}
                        </div>
                        <div className="blog-main__item--text">
                            {t('web.affiliate:Receive a profit share based on your referral performance')}
                        </div>
                        <div className="blog-main__item--link">
                            {t('web.affiliate:READ ARTICLE')}
                            <img draggable="false"
                                src="/svg/arrow.svg"
                                alt="decoration"
                                loading="lazy"
                            />
                        </div>
                    </div>
                    <div className="blog-main__item">
                        <div className="blog-main__item--pic">
                            <img draggable="false"
                                src="/img/blog.png"
                                alt="decoration"
                                loading="lazy"
                            />
                        </div>
                        <div className="blog-main__item--name">
                            {t('web.affiliate:Revenue Share')}
                        </div>
                        <div className="blog-main__item--text">
                            {t('web.affiliate:Receive a profit share based on your referral performance')}
                        </div>
                        <div className="blog-main__item--link">
                            {t('web.affiliate:READ ARTICLE')}
                            <img draggable="false"
                                src="/svg/arrow.svg"
                                alt="decoration"
                                loading="lazy"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="stats">
                <div className="team__header">
                    <h6 className="team__header--text">{t('web.affiliate:ALREADY OUR AFFILIATE?')}</h6>
                    <h4 className="team__header--name">{t('web.affiliate:VIEW YOUR STATS')}</h4>
                </div>
                <div>
                    <a href='https://affiliates.oshcasino.com/login' target="_blank" rel="noopener noreferrer" className="main-button">{t('web.affiliate:ENTER AFFILIATE PANEL')}</a>
                </div>
            </div>
        </div>
    </div>
  );
}

export default App;
